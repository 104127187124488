import { createContext, useContext } from 'react';

type DeviceProviderContext = {
  isMobile: boolean;
};

type DeviceProviderProps = {
  children: any;
  isMobile: boolean;
};

export const DeviceContext = createContext<DeviceProviderContext>({
  isMobile: false,
});

export const useIsMobile = () => {
  const context = useContext(DeviceContext);
  if (!context) {
    throw new Error('useIsMobile must be used within a DeviceProvider');
  }

  return context.isMobile;
};

const DeviceProvider: React.FC<DeviceProviderProps> = ({
  children,
  isMobile,
}) => {
  return (
    <DeviceContext.Provider value={{ isMobile }}>
      {children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
